export const sponsors = {

    "silver": [
        {
            "name": "VMAX Electric Karting",
            "photo": "./sponsors/carpatic_logo_blue.png",
            "description": "Vmax Electric Indoor Karting îți oferă oportunitatea de a simula experiența unui pilot la F1, pe cea mai mare și modernă pistă din sud-estul Europei. Proiectul a fost înființat de către Robert Vișoiu, un pilot profesionist ce a reușit să surprindă emoția unei curse la F1, pe o pistă de 700 de metri. Aceasta beneficiază de cele mai noi tehnologii, fiind creată special pentru a-ți testa limitele și pentru a-ți ține adrenalina la maxim. Intră în spiritul cursei!",
            "link": "https://vmax.ro/"
        },
        {
            "name": "Evadate Escape Rooms",
            "photo": "./sponsors/evadat.png",
            "description": "Vmax Electric Indoor Karting îți oferă oportunitatea de a simula experiența unui pilot la F1, pe cea mai mare și modernă pistă din sud-estul Europei. Proiectul a fost înființat de către Robert Vișoiu, un pilot profesionist ce a reușit să surprindă emoția unei curse la F1, pe o pistă de 700 de metri. Aceasta beneficiază de cele mai noi tehnologii, fiind creată special pentru a-ți testa limitele și pentru a-ți ține adrenalina la maxim. Intră în spiritul cursei!",
            "link": "https://vmax.ro/"
        }
    ],
    "bronze": [
        {
            "name": "Activ Squash",
            "photo": "./sponsors/LogoClub99.png",
            "description": "",
            "link": "https://activ-squash.ro/"
        },
        {
            "name": "Wizrom",
            "photo": "./sponsors/wizrom.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "Trust",
            "photo": "./sponsors/trustTH.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "IDM",
            "photo": "./sponsors/idm.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "LockedIn",
            "photo": "./sponsors/lockedin.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "Blue",
            "photo": "./sponsors/blue.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        }
    ],
    'gold': [
        {
            "name": "The Fool",
            "photo": "./sponsors/Logo_ER.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "Systematic",
            "photo": "./sponsors/systematic.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "Concelex",
            "photo": "./sponsors/concelex1.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "Trickshot",
            "photo": "./sponsors/trickshot.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "Kaya",
            "photo": "./sponsors/kaya.jpg",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        },
        {
            "name": "Atos",
            "photo": "./sponsors/atos.jpg",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        }
    ],
    'coffee': [
        {
            "name": "Coffee",
            "photo": "./sponsors/coffee_shop_logo.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        }
    ],
    'custom': [
        {
            "name": "VMAX Electric Karting",
            "photo": "./sponsors/vmax.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        }
    ],
    'nutrition': [
        {
            "name": "Primola",
            "photo": "./sponsors/primola.png",
            "description": "Având ca rezidenți doi dintre cei mai apreciați comedianți români, Bordea și Micutzu, The Fool este un nou concept de comedy club care aduce în fața publicului cei mai cunoscuți artiști de stand-up din țară, concerte live și momente de entertainment excepționale, toate în locația ultra-centrală din Calea Victoriei. The Fool e clubul de stand up comedy şi spectacole live care face asfaltul de pe Calea Victoriei 118 să dârdâie seară de seară. Suntem singura locaţie de stand up dintr-un beci din Bucureşti şi crede-ne că la ce nebunii se întâmplă pe scena noastră, e mai bine aşa! The Fool a luat naştere în 2018, când trei prieteni s-au decis să se mute împreună. După multă muncă, pasiune şi cardio, în septembrie, Bordea, Micutzu şi Călin au cinstit primul pahar de şpriţ în noua lor casă departe de casă. Şi nebunia abia începea!",
            "link": "https://www.thefool.ro/"
        }
    ]
}
