import React from "react";
import { Container } from "react-bootstrap";
import './sponsors.sass'
import crown from '../../../assets/crown.svg'
import crownbrown from '../../../assets/crownb.svg'
import { HiBadgeCheck } from 'react-icons/hi'

import { sponsors } from "../../../assets/sponsors"
import Sponsor from "./sponsor";
import ComingSoon from "../../coming-soon";

const Sponsors = () => {

    return(
        <Container className="sponsors">
            <Container className="wrapper">
                {/* <span className="label"><p className="large-text">SPONSORS</p></span>
                <ComingSoon /> */}
                {/* <>
                <span className="label">
                    <img className="crown mb-3" src={crown} alt={crown} />
                    <p className="large-text">OFICIAL</p>
                    <img className="crown mb-3" src={crownbrown} alt={crownbrown} />
                </span>

                <Container className="row">
                    {
                        sponsors.oficial.map((sponsor, idx) =>
                            { return <Sponsor key={idx} sponsor={sponsor} /> }
                        )
                    }
                </Container>
                </> */}

<>
                <span className="label">
                    <p className="text bronze">BRONZE</p>
                    <HiBadgeCheck className="icon bronze" />
                </span>

                <Container className="row">
                    {
                        sponsors.bronze.map((sponsor, idx) =>
                            { return <Sponsor key={idx} sponsor={sponsor} /> }
                        )
                    }
                </Container>
                </>

                <>
                <span className="label">
                    <p className="text silver">SILVER</p>
                    <HiBadgeCheck className="icon silver" />
                </span>

                <Container className="row">
                    {
                        sponsors.silver.map((sponsor, idx) =>
                            { return <Sponsor key={idx} sponsor={sponsor} /> }
                        )
                    }
                </Container>
                </>

                <>
                <span className="label">
                    <p className="text gold">GOLD</p>
                    <HiBadgeCheck className="icon gold" />
                </span>

                <Container className="row">
                    {
                        sponsors.gold.map((sponsor, idx) =>
                            { return <Sponsor key={idx} sponsor={sponsor} /> }
                        )
                    }
                </Container>
                </>

                <>
                <span className="label">
                    <p className="text bronze">COFFEE PARTNER</p>
                    <HiBadgeCheck className="icon bronze" />
                </span>

                <Container className="row">
                    {
                        sponsors.coffee.map((sponsor, idx) =>
                            { return <Sponsor key={idx} sponsor={sponsor} /> }
                        )
                    }
                </Container>
                </>

                <>
                <span className="label">
                    <p className="text gold">NUTRITION PARTNER</p>
                    <HiBadgeCheck className="icon gold" />
                </span>

                <Container className="row">
                    {
                        sponsors.nutrition.map((sponsor, idx) =>
                            { return <Sponsor key={idx} sponsor={sponsor} /> }
                        )
                    }
                </Container>
                </>
                
                <>
                <span className="label">
                        <p className="text custom">CUSTOM</p>
                        <HiBadgeCheck className="icon custom " />
                </span>

                <Container className="row">
                    <div className="sponsorsLogos">
                    {
                        sponsors.custom.map((sponsor, idx) =>
                            { return <Sponsor key={idx} sponsor={sponsor} /> }
                        )
                    }
                    </div>
                </Container>
                </>
            </Container>
        </Container>
    );
}

export default Sponsors
