import React, {useContext, useEffect, useState} from "react";
import {Row, Image, Col, InputGroup, Container, Button, Form, Nav} from "react-bootstrap";
import { login, register } from "../../network/apiCall";
import { useNavigate } from "react-router-dom";
import './register.sass';
import AppContext from "../../context";
import { GrMail } from "react-icons/gr"
import { AiFillPhone, AiFillApple} from 'react-icons/ai'
import { BsPersonFill } from 'react-icons/bs'
import { HiLockClosed } from "react-icons/hi"
import { ImArrowRight } from "react-icons/im"

import loginIcon from '../../assets/login.png'

const Register = () => {

    const {isLoggedIn, setIsLoggedIn, loading} = useContext(AppContext);
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [username, setUsername] = useState("");
    const [noTeam, setNoTeam] = useState(false);
    const [isUPB, setIsUPB] = useState(false);
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [appleID, setAppleID] = useState("");

    const [error, setError] = useState("");

    useEffect(() => {
        if (!loading && isLoggedIn) {
            navigate('/profile');
        }
    }, [loading]);

    const onChangeEmail = e => {
        setEmail(e.target.value);
    };
    const onChangeFirstName = e => {
        setFirstName(e.target.value);
    };
    const onChangeLastName = e => {
        setLastName(e.target.value);
    };
    const onChangePhoneNumber = e => {
        setPhoneNumber(e.target.value);
    };
    const onChangeUsername = e => {
        setUsername(e.target.value);
    };
    const onChangePassword = e => {
        setPassword(e.target.value);
    };
    const onChangeConfPassword = e => {
        setConfPassword(e.target.value);
    };
    const onChangeAppleID = e => { 
        setAppleID(e.target.value);
    }
    const onSubmitHandler = async () => {
        const {data} = await register(email, password, confPassword, username, lastName, firstName, phoneNumber, noTeam, isUPB, appleID);
        if (data.success) {
            const {data} = await login(email, password);
            if (data.success) {
                localStorage.setItem("token", data.data.token);
                setIsLoggedIn(true);
                navigate('/profile');
                if (noTeam) {
                    navigate('/profile');
                } else {
                    navigate('/team-setup');
                }
            } else {
                navigate('/login')
            }
            window.location.reload(false);
        } else {
            setError(data.data.error);
        }
    };

    return(
        <Container className="register">
            <Row>
                <Col md={6} className="text-center image-container p-md-5 p-5 order-md-2">
                    <Image className="w-75 w-md-50" src={loginIcon} alt={loginIcon}></Image>
                </Col>
                <Col md={6} sm={12} xs={12} className="left-side my-auto order-md-1">
                    <p className="large-text">Step 1: Create your account</p>

                    <Form className="pt-5 my-form">
                        <InputGroup className="mb-2">
                            <Form.Control type="email" placeholder="Email" onChange={e => onChangeEmail(e)} value={email}/>
                            <GrMail className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control placeholder="Apple ID (optional)" onChange={e => onChangeAppleID(e)} value={appleID} />
                            <AiFillApple className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control placeholder="First Name" onChange={e => onChangeFirstName(e)} value={firstName}/>
                            <BsPersonFill className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control placeholder="Last Name" onChange={e => onChangeLastName(e)} value={lastName}/>
                            <BsPersonFill className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control placeholder="Phone Number" onChange={e => onChangePhoneNumber(e)} value={phoneNumber}/>
                            <AiFillPhone className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control placeholder="Username" onChange={e => onChangeUsername(e)} value={username}/>
                            <BsPersonFill className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control type="password" placeholder="Password" onChange={e => onChangePassword(e)} value={password}/>
                            <HiLockClosed className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control type="password" placeholder="Confirm Password" onChange={e => onChangeConfPassword(e)} value={confPassword}/>
                            <HiLockClosed className="form-icon" />
                        </InputGroup>
                        <p className="text">Te rugăm să selectezi toate opțiunile care se aplică:</p>
                        <InputGroup className="mb-2 mt-3">
                            <Form.Check type="checkbox" value={noTeam} onChange={function () { setNoTeam(!noTeam)}} label="Vreau să mă înscriu singur și îmi doresc să fiu asignat aleator într-o echipă de către organizatori." />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Check type="checkbox" value={isUPB} onChange={function () { setIsUPB(!isUPB)}} label="Sunt student la Universitatea Națională de Știință și Tehnologie București." />
                        </InputGroup>
                        {error && <p className="error">{error}</p>}
                        <div className="text-center">
                            <Button variant="primary" onClick={onSubmitHandler}>
                                Continue
                                <ImArrowRight className="trailing-icon" />
                            </Button>
                        </div>
                        <div className="text-center mt-2">
                            <p>Already have an account? <Nav.Link href="/login">Login</Nav.Link></p>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Register;
